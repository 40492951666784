import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import { isLoggedIn } from '@/utils/auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    children: [
      {
        path: '/home',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/Dashboard.vue'),
      },
      // {
      //   path: '/theme',
      //   name: 'Theme',
      //   redirect: '/theme/typography',
      // },
      // {
      //   path: '/theme/colors',
      //   name: 'Colors',
      //   component: () => import('@/views/theme/Colors.vue'),
      // },
      // {
      //   path: '/theme/typography',
      //   name: 'Typography',
      //   component: () => import('@/views/theme/Typography.vue'),
      // },
      {
        path: '/monitor',
        name: 'Monitores',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/monitor/lista',
        children: [
          {
            path: '/monitor/lista',
            name: 'Lista',
            component: () => import('@/views/monitor/MonitorListaViews.vue'),
          },
          {
            path: '/monitor/novo',
            name: 'Novo',
            component: () => import('@/views/monitor/MonitorFromsViews.vue'),
          },
          {
            path: '/monitor/editar/:id',
            name: 'Editar',
            props: true,
            meta: {
              par: true,
            },
            component: () => import('@/views/monitor/MonitorFromsViews.vue'),
          },
          // {
          //   path: '/buttons/dropdowns',
          //   name: 'Dropdowns',
          //   component: () => import('@/views/buttons/Dropdowns.vue'),
          // },
          // {
          //   path: '/buttons/button-groups',
          //   name: 'Button Groups',
          //   component: () => import('@/views/buttons/ButtonGroups.vue'),
          // },
        ],
      },
      {
        path: '/agendamento',
        name: 'Agendamento',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/agendamento/lista',
        children: [
          {
            path: '/agendamento/lista',
            name: 'Lista de agendamentos',
            component: () =>
              import('@/views/agendamento/AgendamentoListaVisitaViews.vue'),
          },
          {
            path: '/agendamento/calendario',
            name: 'Calendário',
            component: () =>
              import('@/views/agendamento/AgendamentoCalendarioViews.vue'),
          },
          {
            path: '/agendamento/visita/:id',
            name: 'Detalhes da visita',
            props: true,
            meta: {
              par: true,
            },
            component: () =>
              import('@/views/agendamento/AgendamentoDetailsViews.vue'),
          },
        ],
      },
      {
        path: '/participantes',
        name: 'Participantes',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/participantes/lista',
        children: [
          {
            path: '/participantes/lista',
            name: 'Visitas',
            component: () =>
              import('@/views/participante/VisitaListaViews.vue'),
          },
          {
            path: '/participantes/visita/:id',
            name: 'Lista de participantes',
            props: true,
            meta: {
              par: true,
            },
            component: () =>
              import('@/views/participante/ListaParticipantesViews.vue'),
          },
        ],
      },
      {
        path: '/cuco',
        name: 'CUCO',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/cuco/colaboradores/professores/lista',
        children: [
          {
            path: '/cuco/colaboradores/professores/lista',
            name: 'Professores',
            component: () =>
              import('@/views/cuco/CucoProfessoresListaViews.vue'),
          },
          {
            path: '/cuco/colaboradores/professores/:id/editar',
            name: 'Editar Professor',
            component: () => import('@/views/cuco/CucoEdicaoProfessorView.vue'),
          },
          {
            path: '/cuco/colaboradores/de/:id/editar',
            name: 'Editar Diretor de Ensino',
            component: () => import('@/views/cuco/CucoEdicaoDeView.vue'),
          },
          {
            path: '/cuco/colaboradores/de/lista',
            name: 'Diretores de Ensino',
            component: () => import('@/views/cuco/CucoDeListaViews.vue'),
          },
          {
            path: '/cuco/edicoes/cuco-23',
            name: 'CUCO 2023',
            component: () =>
              import('@/views/cuco/CucoEdicaoInscricoesView.vue'),
            meta: { idCuco: 'cuco-23' },
          },
          {
            path: '/cuco/edicoes/cuco-24',
            name: 'CUCO 2024',
            component: () =>
              import('@/views/cuco/CucoEdicaoInscricoesView.vue'),
            meta: { idCuco: 'cuco-24' },
          },
          {
            path: '/cuco/alunos',
            name: 'Alunos CUCO',
            component: () => import('@/views/cuco/CucoGeralAlunoView.vue'),
          },
          {
            path: '/cuco/alunos/:id/visualizar',
            name: 'Visualizar Aluno CUCO',
            component: () => import('@/views/cuco/CucoVisualizarAlunoView.vue'),
          },
          {
            path: '/cuco/alunos/:id/editar',
            name: 'Editar Aluno CUCO',
            component: () => import('@/views/cuco/CucoEdicaoAlunoView.vue'),
          },
          {
            path: '/cuco/edicoes/:id/dados',
            name: 'Dados - CUCO 2023',
            component: () => import('@/views/cuco/CucoDadosView.vue'),
          },
          {
            path: '/cuco/edicoes/:id/relatorios',
            name: 'Relatórios CUCO',
            props: true,
            component: () =>
              import('@/views/cuco/CucoEdicaoInscricoesRelatorioView.vue'),
          },

          {
            path: '/cuco/questoes',
            name: 'Banco de Questões',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/cuco/questoes/lista',
            children: [
              {
                path: '/cuco/questoes/lista',
                name: 'Questões',
                component: () =>
                  import('@/views/bancoQuestoes/QuestoesListaViews.vue'),
              },
              {
                path: '/cuco/questoes/editar/:id',
                props: true,
                name: 'Editar Questões CUCO',
                component: () =>
                  import('@/views/bancoQuestoes/EditarQuestoesView.vue'),
              },
              {
                path: '/cuco/questoes/novo',
                props: true,
                name: 'Nova Questões CUCO',
                component: () =>
                  import('@/views/bancoQuestoes/EditarQuestoesView.vue'),
              },
              {
                path: '/cuco/questoes/pre-visualizacao/:id',
                props: true,
                name: 'Visualizar Questões CUCO',
                component: () =>
                  import('@/views/bancoQuestoes/PreQuestoesView.vue'),
              },
              {
                path: '/cuco/questoes/pre-visualizacao',
                props: true,
                name: 'Visualizar todas Questões CUCO',
                component: () =>
                  import('@/views/bancoQuestoes/PreQuestoesAllView.vue'),
              },
              {
                path: '/cuco/teste/novo',
                props: true,
                name: 'Novo Teste CUCO',
                component: () =>
                  import('@/views/bancoQuestoes/GeraTesteView.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/emails',
        name: 'E-mails',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/emails/lista',
        children: [
          {
            path: '/emails/lista',
            name: 'Lista de envios',
            component: () => import('@/views/emails/EmailsListaView.vue'),
          },
          {
            path: '/emails/novo',
            name: 'Novo envio',
            component: () => import('@/views/emails/EmailsNovoView.vue'),
          },
          {
            path: '/emails/envio/:id/:page',
            props: true,
            name: 'Detalhes de envio',
            component: () => import('@/views/emails/EmailsDetalhesView.vue'),
          },

          {
            path: '/emails/modelos',
            name: 'Modelos',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            redirect: '/emails/modelos/lista',
            children: [
              {
                path: '/emails/modelos/lista',
                name: 'Modelos de e-mails',
                component: () =>
                  import('@/views/emails/EmailsListaModeloView.vue'),
              },
              {
                path: '/emails/modelos/novo',
                name: 'Novo Modelo de e-mails',
                component: () =>
                  import('@/views/emails/EmailsNovoModeloView.vue'),
              },
            ],
          },
        ],
      },
      // {
      //   path: '/base',
      //   name: 'Base',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/base/breadcrumbs',
      //   children: [
      //     {
      //       path: '/base/accordion',
      //       name: 'Accordion',
      //       component: () => import('@/views/base/Accordion.vue'),
      //     },
      //     {
      //       path: '/base/breadcrumbs',
      //       name: 'Breadcrumbs',
      //       component: () => import('@/views/base/Breadcrumbs.vue'),
      //     },
      //     {
      //       path: '/base/cards',
      //       name: 'AAAAAAAAAAA',
      //       component: () => import('@/views/base/Cards.vue'),
      //     },
      //     {
      //       path: '/base/carousels',
      //       name: 'Carousels',
      //       component: () => import('@/views/base/Carousels.vue'),
      //     },
      //     {
      //       path: '/base/collapses',
      //       name: 'Collapses',
      //       component: () => import('@/views/base/Collapses.vue'),
      //     },
      //     {
      //       path: '/base/list-groups',
      //       name: 'List Groups',
      //       component: () => import('@/views/base/ListGroups.vue'),
      //     },
      //     {
      //       path: '/base/navs',
      //       name: 'Navs',
      //       component: () => import('@/views/base/Navs.vue'),
      //     },
      //     {
      //       path: '/base/paginations',
      //       name: 'Paginations',
      //       component: () => import('@/views/base/Paginations.vue'),
      //     },
      //     {
      //       path: '/base/placeholders',
      //       name: 'Placeholders',
      //       component: () => import('@/views/base/Placeholders.vue'),
      //     },
      //     {
      //       path: '/base/popovers',
      //       name: 'Popovers',
      //       component: () => import('@/views/base/Popovers.vue'),
      //     },
      //     {
      //       path: '/base/progress',
      //       name: 'Progress',
      //       component: () => import('@/views/base/Progress.vue'),
      //     },
      //     {
      //       path: '/base/spinners',
      //       name: 'Spinners',
      //       component: () => import('@/views/base/Spinners.vue'),
      //     },
      //     {
      //       path: '/base/tables',
      //       name: 'Tables',
      //       component: () => import('@/views/base/Tables.vue'),
      //     },
      //     {
      //       path: '/base/tooltips',
      //       name: 'Tooltips',
      //       component: () => import('@/views/base/Tooltips.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/buttons',
      //   name: 'Buttons',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/buttons/standard-buttons',
      //   children: [
      //     {
      //       path: '/buttons/standard-buttons',
      //       name: 'Buttons',
      //       component: () => import('@/views/buttons/Buttons.vue'),
      //     },
      //     {
      //       path: '/buttons/dropdowns',
      //       name: 'Dropdowns',
      //       component: () => import('@/views/buttons/Dropdowns.vue'),
      //     },
      //     {
      //       path: '/buttons/button-groups',
      //       name: 'Button Groups',
      //       component: () => import('@/views/buttons/ButtonGroups.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/forms',
      //   name: 'Forms',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/forms/form-control',
      //   children: [
      //     {
      //       path: '/forms/form-control',
      //       name: 'Form Control',
      //       component: () => import('@/views/forms/FormControl.vue'),
      //     },
      //     {
      //       path: '/forms/select',
      //       name: 'Select',
      //       component: () => import('@/views/forms/Select.vue'),
      //     },
      //     {
      //       path: '/forms/checks-radios',
      //       name: 'Checks & Radios',
      //       component: () => import('@/views/forms/ChecksRadios.vue'),
      //     },
      //     {
      //       path: '/forms/range',
      //       name: 'Range',
      //       component: () => import('@/views/forms/Range.vue'),
      //     },
      //     {
      //       path: '/forms/input-group',
      //       name: 'Input Group',
      //       component: () => import('@/views/forms/InputGroup.vue'),
      //     },
      //     {
      //       path: '/forms/floating-labels',
      //       name: 'Floating Labels',
      //       component: () => import('@/views/forms/FloatingLabels.vue'),
      //     },
      //     {
      //       path: '/forms/layout',
      //       name: 'Layout',
      //       component: () => import('@/views/forms/Layout.vue'),
      //     },
      //     {
      //       path: '/forms/validation',
      //       name: 'Validation',
      //       component: () => import('@/views/forms/Validation.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/charts',
      //   name: 'Charts',
      //   component: () => import('@/views/charts/Charts.vue'),
      // },
      // {
      //   path: '/icons',
      //   name: 'Icons',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/icons/coreui-icons',
      //   children: [
      //     {
      //       path: '/icons/coreui-icons',
      //       name: 'CoreUI Icons',
      //       component: () => import('@/views/icons/CoreUIIcons.vue'),
      //     },
      //     {
      //       path: '/icons/brands',
      //       name: 'Brands',
      //       component: () => import('@/views/icons/Brands.vue'),
      //     },
      //     {
      //       path: '/icons/flags',
      //       name: 'Flags',
      //       component: () => import('@/views/icons/Flags.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/notifications',
      //   name: 'Notifications',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/notifications/alerts',
      //   children: [
      //     {
      //       path: '/notifications/alerts',
      //       name: 'Alerts',
      //       component: () => import('@/views/notifications/Alerts.vue'),
      //     },
      //     {
      //       path: '/notifications/badges',
      //       name: 'Badges',
      //       component: () => import('@/views/notifications/Badges.vue'),
      //     },
      //     {
      //       path: '/notifications/modals',
      //       name: 'Modals',
      //       component: () => import('@/views/notifications/Modals.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/widgets',
      //   name: 'Widgets',
      //   component: () => import('@/views/widgets/Widgets.vue'),
      // },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginViews.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/pages/Page404'),
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.path == '/login' && isLoggedIn()) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
})

export default router

<template>
  <div>
    <Loading />
    <NotificacoesErro />
    <NotificacaoAlert />
    <ToastAlert />
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <Suspense>
          <template #default>
            <router-view />
          </template>
          <template #fallback>
            <div class="loading-page">
              <span class="loader"></span>
              <div class="text-loader">CARREGANDO...</div>
            </div>
          </template>
        </Suspense>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
// import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import NotificacoesErro from '@/components/NotificacaoErro.vue'
import NotificacaoAlert from '@/components/Notificacao.vue'
import ToastAlert from '@/components/ToastAlert.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    NotificacoesErro,
    NotificacaoAlert,
    ToastAlert,
    Loading,
    // CContainer,
  },
}
</script>
<style scoped>
.loading-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
}
.text-loader {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 10px;
}
.loader {
  width: 40px;
  height: 40px;
  border: 6px solid #f47935;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

import http from '@/http'
import authHeader from './auth-header'

class AgendamentoService {
  async get() {
    const response = await http.get('calendario', { headers: authHeader() })
    return response.data.reverse()
  }
  async getAgendamento() {
    const response = await http.get('agendamento', { headers: authHeader() })
    return response.data.reverse()
  }
  async getComprovante(key) {
    const response = await http.post(
      `agendamento/comprovante`,
      {
        key,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async getById(id) {
    const response = await http.get(`agendamento/${id}`, {
      headers: authHeader(),
    })
    return response.data
  }
  async delete(id) {
    const response = await http.delete(`calendario/remover/${id}`, {
      headers: authHeader(),
    })
    return response.data
  }
  async post({ date, total, type, status }) {
    const response = await http.post(
      'calendario/novo',
      {
        date,
        total,
        type,
        status,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async put({ type, status, total, idCalendar }) {
    const response = await http.put(
      `/calendario/atualizar/${idCalendar}`,
      {
        type,
        total,
        status,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async atualizaAgendamentoStatus({
    status,
    noteCorrection,
    inputsCorrection,
    idScheduling,
  }) {
    const response = await http.put(
      `/agendamento/atualizar/status/${idScheduling}`,
      {
        noteCorrection,
        status,
        inputsCorrection,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async atualizaAgendamentoMonitor({ idMonitor, idScheduling }) {
    const response = await http.put(
      `/agendamento/atualizar/monitor/${idScheduling}`,
      {
        idMonitor,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async cancelaAgendamento({ cancellationScheduling, idScheduling }) {
    const response = await http.put(
      `/agendamento/adm/cancelar/${idScheduling}`,
      {
        cancellationScheduling,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async faltaAgendamento({ idScheduling }) {
    const response = await http.get(
      `/agendamento/atualizar/falta/${idScheduling}`,
      { headers: authHeader() },
    )
    return response.data
  }
  async atualizarPagamento({ statusPay, idScheduling }) {
    const response = await http.put(
      `/agendamento/atualizar/pagamento/${idScheduling}`,
      {
        statusPay,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async uploadPagamento({ data, idScheduling }) {
    const headersUp = authHeader()
    headersUp['Content-Type'] = 'multipart/form-data'

    console.log(data)
    const response = await http.put(
      `/agendamento/atualizar/comprovante/${idScheduling}`,
      data,
      {
        headers: headersUp,
      },
    )
    return response.data
  }
}

export default new AgendamentoService()

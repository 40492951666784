<template>
  <router-view />
</template>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';

.btn {
  color: #fff !important;
}
.ml-1 {
  margin-left: 0.5rem;
}
a {
  text-decoration: none;
}
</style>

<template>
  <div class="bg-loading" v-if="isLoading">
    <div class="loader"></div>
  </div>
</template>
<style>
.bg-loading {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid rgba(255, 255, 255, 0.2);
  border-right: 0.8em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.8em solid rgba(255, 255, 255, 0.2);
  border-left: 0.8em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
<script>
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'Loading',
  setup() {
    const store = useStore()

    return {
      isLoading: computed(() => store.state.isLoading),
    }
  },
})
</script>

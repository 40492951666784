import AgendamentoService from '@/services/agendamento.service'

export const agendamento = {
  state: {
    calendario: [],
    agendamentos: [],
  },
  actions: {
    async obterCalendario({ commit }) {
      commit('isLoading')
      try {
        const response = await AgendamentoService.get()
        commit('definirCalendario', response)
        return response
      } catch (erro) {
        return erro
      }
    },
    async cadastrarCalendario({ commit }, data) {
      commit('isLoading')
      const response = await AgendamentoService.post(data)
      commit('adicionarCalendario', response)
      return response
    },
    async editarCalendario({ commit }, data) {
      commit('isLoading')
      const response = await AgendamentoService.put(data)
      commit('alterarCalendario', response)
      return response
    },
    async atualizarAgendamentoStatus({ commit }, data) {
      commit('isLoading')
      const response = await AgendamentoService.atualizaAgendamentoStatus(data)
      commit('alterarAgendamento', response)
      return response
    },
    async atualizarAgendamentoPagamento({ commit }, data) {
      commit('isLoading')
      const response = await AgendamentoService.atualizarPagamento(data)
      commit('alterarAgendamento', response)
      return response
    },
    async uploadPagamento({ commit }, data) {
      commit('isLoading')
      const response = await AgendamentoService.uploadPagamento(data)
      commit('alterarAgendamento', response)
      return response
    },
    async cancelaAgendamento({ commit }, data) {
      commit('isLoading')
      const response = await AgendamentoService.cancelaAgendamento(data)
      commit('alterarAgendamento', response)
      return response
    },
    async faltaAgendamento({ commit }, data) {
      commit('isLoading')
      const response = await AgendamentoService.faltaAgendamento(data)
      commit('alterarAgendamento', response)
      return response
    },
    async obterAgendamentoById({ commit }, id) {
      commit('isLoading')
      const response = await AgendamentoService.getById(id)
      commit('alterarAgendamento', response)
      return response
    },
    async deletarCalendario({ commit }, id) {
      commit('isLoading')
      return await AgendamentoService.delete(id).then(() =>
        commit('excluirCalendario', id),
      )
    },

    async obterAgendamento({ commit }) {
      //commit('isLoading')
      try {
        const response = await AgendamentoService.getAgendamento()
        commit('definirAgendamentos', response)
        return response
      } catch (erro) {
        return erro
      }
    },
  },
  mutations: {
    definirCalendario(state, calendario) {
      state.calendario = calendario
    },
    adicionarCalendario(state, data) {
      state.calendario.push(data)
    },
    excluirCalendario(state, id) {
      state.calendario = state.calendario.filter(
        (data) => data.idCalendar != id,
      )
    },
    alterarCalendario(state, dataUpdate) {
      state.calendario = state.calendario.filter(
        (data) => data.idCalendar != dataUpdate.idCalendar,
      )
      state.calendario.push(dataUpdate)
    },
    definirAgendamentos(state, agendamentos) {
      state.agendamentos = agendamentos
    },
    alterarAgendamento(state, dataUpdate) {
      state.agendamentos = state.agendamentos.filter(
        (data) => data.idScheduling != dataUpdate.idScheduling,
      )
      state.agendamentos.push(dataUpdate)
    },
  },
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { key, store } from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import VueGoodTablePlugin from 'vue-good-table-next'

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import VCalendar from 'v-calendar'
// import 'v-calendar/dist/style.css'

import JsonExcel from 'vue-json-excel3'

import { VueCsvImportPlugin } from 'vue-csv-import'

import VueMask from '@devindex/vue-mask'

/* import specific icons */
import {
  faUserSecret,
  faTrash,
  faPenToSquare,
  faPlus,
  faArrowsRotate,
  faCircleExclamation,
  faTriangleExclamation,
  faCheck,
  faHouse,
  faSquareArrowUpRight,
  faEye,
  faSchoolFlag,
  faUsers,
  faUserGroup,
  faAddressCard,
  faBan,
  faReceipt,
  faCheckToSlot,
  faFilePen,
  faEnvelope,
  faAddressBook,
  faStar,
  faStarHalfStroke,
  faQrcode,
  faCloudDownload,
  faFileExcel,
  faPaperclip,
  faFlag,
  faTimes,
  faPencil,
  faCaretLeft,
  faCaretRight,
  faFile,
  faKey,
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faUserSecret,
  faTrash,
  faPenToSquare,
  faPlus,
  faArrowsRotate,
  faCircleExclamation,
  faTriangleExclamation,
  faCheck,
  faHouse,
  faSquareArrowUpRight,
  faEye,
  faSchoolFlag,
  faUsers,
  faUserGroup,
  faAddressCard,
  faBan,
  faReceipt,
  faCheckToSlot,
  faFilePen,
  faEnvelope,
  faAddressBook,
  faStar,
  faStarHalfStroke,
  faQrcode,
  faCloudDownload,
  faFileExcel,
  faPaperclip,
  faFlag,
  faTimes,
  faPencil,
  faCaretLeft,
  faCaretRight,
  faFile,
  faKey,
)

const app = createApp(App)
app.use(store, key)
app.use(router)
app.use(CoreuiVue)
app.use(PerfectScrollbar)
// app.use(VCalendar)
app.use(VueGoodTablePlugin)
app.use(VueCsvImportPlugin)
app.use(VueMask)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('downloadExcel', JsonExcel)

app.mount('#app')

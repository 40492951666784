import AuthService from '@/services/auth.service'

const adm = JSON.parse(localStorage.getItem('adm'))
const initialState = adm
  ? { status: { loggedIn: true }, adm }
  : { status: { loggedIn: false }, adm: null }

export const auth = {
  // namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, adm) {
      return AuthService.login(adm).then(
        (adm) => {
          console.log(adm)
          commit('loginSuccess', adm)
          return Promise.resolve(adm)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )
    },
    // logout({ commit }) {
    //   AuthService.logout()
    //   commit('logout')
    // },
    // register({ commit }, adm) {
    //   return AuthService.register(adm).then(
    //     (response) => {
    //       commit('registerSuccess')
    //       return Promise.resolve(response.data)
    //     },
    //     (error) => {
    //       commit('registerFailure')
    //       return Promise.reject(error)
    //     },
    //   )
    // },
  },
  mutations: {
    loginSuccess(state, adm) {
      state.status.loggedIn = true
      state.adm = adm
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.adm = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.adm = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
  },
}

import http from '@/http'

class AuthService {
  login({ emailAdm, passwordAdm }) {
    return http
      .post('adm/login', {
        emailAdm,
        passwordAdm,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem('adm', JSON.stringify(response.data.token))
        }
        return response.data
      })
  }

  logout() {
    localStorage.removeItem('adm')
  }
}

export default new AuthService()

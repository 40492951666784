<template>
  <CFooter>
    <div>
      <a href="#" target="_blank">Vem Saber</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}</span>
    </div>
    <div class="ms-auto"></div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

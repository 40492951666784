import http from '@/http'
import authHeader from './auth-header'

class MonitorService {
  async get() {
    const response = await http.get('monitor', { headers: authHeader() })
    return response.data.reverse()
  }
  async getById(id) {
    const response = await http.get(`monitor/${id}`, { headers: authHeader() })
    return response.data
  }
  async find(id) {
    const response = await http.get(`monitor/find/${id}`, {
      headers: authHeader(),
    })
    return response.data
  }
  async delete(id) {
    const response = await http.delete(`monitor/remover/${id}`, {
      headers: authHeader(),
    })
    return response.data
  }
  async post({ nameMonitor, emailMonitor }) {
    const response = await http.post(
      'monitor/novo',
      {
        nameMonitor,
        emailMonitor,
      },
      { headers: authHeader() },
    )
    return response.data
  }
  async put({ nameMonitor, emailMonitor, idMonitor }) {
    const response = await http.put(
      `/monitor/atualizar/${idMonitor}`,
      {
        nameMonitor,
        emailMonitor,
      },
      { headers: authHeader() },
    )
    return response.data
  }
}

export default new MonitorService()

import MonitorService from '@/services/monitor.service'

export const monitor = {
  state: {
    monitores: [],
  },
  actions: {
    obterMonitor({ commit }) {
      return MonitorService.get().then(
        (monitores) => {
          commit('definirMonitor', monitores)
          return Promise.resolve(monitores)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
    },
    async cadastrarMonitor({ commit }, monitor) {
      commit('isLoading')
      return await MonitorService.post(monitor)
    },
    async alterarMonitor({ commit }, monitor) {
      commit('isLoading')
      return await MonitorService.put(monitor)
    },
    async obterMonitorById({ commit }, id) {
      commit('isLoading')
      return await MonitorService.getById(id)
    },
    async deletarMonitor({ commit }, id) {
      commit('isLoading')
      return await MonitorService.delete(id).then(() =>
        commit('excluirMonitor', id),
      )
    },
  },
  mutations: {
    definirMonitor(state, monitores) {
      state.monitores = monitores
    },
    excluirMonitor(state, id) {
      state.monitores = state.monitores.filter(
        (monitor) => monitor.idMonitor != id,
      )
    },
  },
}

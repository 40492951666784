import ParticipanteService from '@/services/participante.service'

export const participante = {
  state: {},
  actions: {
    async obterVisitas() {
      return await ParticipanteService.getVisitas()
    },
    async obterParticipante({ commit }, id) {
      commit('isLoading')
      return await ParticipanteService.getParticipantes(id)
    },
  },
  mutations: {},
}

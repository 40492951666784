import { createStore, useStore as vuexUseStore } from 'vuex'
import { auth } from './auth'
import { monitor } from './monitor'
import { agendamento } from './agendamento'
import { participante } from './participante'

export const key = Symbol()
export const store = createStore({
  state: {
    sidebarVisible: true,
    sidebarUnfoldable: false,
    notificacoes: {},
    modalAlert: {},
    toastAlert: [],
    isLoading: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    notificarErro(state, novaNotificacao) {
      novaNotificacao.id = new Date().getTime()
      state.notificacoes = novaNotificacao
    },
    notificarErroUpdate(state) {
      state.notificacoes = {}
    },
    modalAlert(state, novoAlert) {
      state.modalAlert = novoAlert
    },
    modalAlertUpdate(state) {
      state.modalAlert = {}
    },
    toastAlert(state, novoToast) {
      state.toastAlert.push(novoToast)
    },
    isLoading(state) {
      state.isLoading = !state.isLoading
    },
    setLoading(state, newStatus) {
      state.isLoading = newStatus
    },
  },
  actions: {
    updateLoading({ commit }) {
      commit('isLoading')
    },
    statusLoading({ commit }, status) {
      commit('setLoading', status)
    },
  },
  modules: {
    auth,
    monitor,
    agendamento,
    participante,
  },
})

export function useStore() {
  return vuexUseStore(key)
}

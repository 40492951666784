<template>
  <CModal alignment="center" backdrop="static" :visible="notificacao.status">
    <CModalHeader :close-button="false">
      <CModalTitle>Sucesso</CModalTitle>
    </CModalHeader>
    <CModalBody>
      {{ notificacao.texto }}
    </CModalBody>
    <CModalFooter>
      <CButton
        color="success"
        @click="
          () => {
            closeAlert(notificacao)
          }
        "
      >
        OK
      </CButton>
    </CModalFooter>
  </CModal>
</template>
<script>
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import useNotificador from '@/hooks/notificador'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'NotificacoesAlert',
  setup() {
    const { modalAlertUpdate } = useNotificador()

    const router = useRouter()
    const store = useStore()

    const closeAlert = (notificacao) => {
      modalAlertUpdate()
      router.push(notificacao.retorno)
    }

    return {
      notificacao: computed(() => store.state.modalAlert),
      closeAlert,
    }
  },
})
</script>

<template>
  <CToaster placement="bottom-end">
    <CToast
      :autohide="true"
      v-for="(toast, index) in toasts"
      :key="index"
      visible
      :delay="3000"
      class="border-0 rounded"
    >
      <div class="d-flex">
        <CToastBody class="flex-column d-flex w-100">
          <div class="d-flex flex-row">
            <div :class="`div_left bg-${toast.type} rounded h-100`" />
            <div class="flex-column d-flex ms-2 w-100">
              <div
                class="d-flex justify-content-between mb-2 align-items-center"
              >
                <span :class="`text_w fw-bold text-${toast.type}`">{{
                  toast.title
                }}</span>
                <CToastClose class="w_close me-2 m-auto" />
              </div>
              <span class="text-black">{{ toast.text }}</span>
            </div>
          </div>
        </CToastBody>
      </div>
    </CToast>
  </CToaster>
</template>
<style>
.div_left {
  width: 8px;
}
.text_w {
  font-size: 1rem;
}
.w_close {
  width: 5px;
  height: 5px;
}
</style>
<script>
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'ToastAlert',
  setup() {
    const store = useStore()
    return {
      toasts: computed(() => store.state.toastAlert),
    }
  },
})
</script>

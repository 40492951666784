<template>
  <CModal alignment="center" backdrop="static" :visible="notificacoes.status">
    <CModalHeader :close-button="false">
      <CModalTitle>Erro</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div v-for="erro in notificacoes.erros" :key="erro">
        <div class="message-body">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          {{ erro }}
        </div>
      </div>
    </CModalBody>
    <CModalFooter>
      <CButton
        color="danger"
        @click="
          () => {
            updateNotificaErro()
          }
        "
      >
        OK
      </CButton>
    </CModalFooter>
  </CModal>
</template>
<script>
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import useNotificador from '@/hooks/notificador'

export default defineComponent({
  name: 'NotificacoesErro',
  data() {
    return {
      visibleVerticallyCenteredDemo: false,
    }
  },
  setup() {
    const { updateNotificaErro } = useNotificador()

    const store = useStore()
    return {
      notificacoes: computed(() => store.state.notificacoes),
      updateNotificaErro,
    }
  },
})
</script>

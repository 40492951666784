import decode from 'jwt-decode'

const AUTH_TOKEN_KEY = 'adm'

export function getAuthToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY)
}

// export function clearAuthToken() {
//   axios.defaults.headers.common['Authorization'] = ''
//   localStorage.removeItem(AUTH_TOKEN_KEY)
// }

export function isLoggedIn() {
  let authToken = getAuthToken()
  return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
  if (isLoggedIn()) {
    return decode(getAuthToken())
  }
}

function getTokenExpirationDate(encodedToken) {
  try {
    let token = decode(encodedToken)
    if (!token.exp) {
      return null
    }

    let date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
  } catch (error) {
    return null
  }
}

function isTokenExpired(token) {
  let expirationDate = getTokenExpirationDate(token)
  return expirationDate < new Date()
}

import { store } from '@/store'

export default () => {
  const notificar = (status, erros) => {
    store.commit('notificarErro', {
      erros,
      status,
    })
  }
  const modalAlert = (status, texto, retorno) => {
    store.commit('modalAlert', {
      status,
      texto,
      retorno,
    })
  }
  const modalAlertUpdate = () => {
    store.commit('modalAlertUpdate')
  }
  const updateNotificaErro = () => {
    store.commit('notificarErroUpdate')
  }

  const toastAlert = (status, title, text, type) => {
    store.commit('toastAlert', {
      status,
      title,
      text,
      type,
    })
  }

  return {
    notificar,
    modalAlert,
    modalAlertUpdate,
    updateNotificaErro,
    toastAlert,
  }
}
